export const environment = {
  production: true,
  pointType:"pre",
  laneAxisDotCom: 'https://sandbox.laneaxis.com',
  apiEndpoint: 'https://api-pre.laneaxis.com',
  domainPoint: 'https://pre-network.laneaxis.com',
  searchRestriction: ['us'],
  searchMultipleRestriction: ['us', 'ca', 'mx'],
  stripePublicKey: 'pk_test_jqNFapP28xsD2U7ZWC4sH1Bl00n5lthUOE',
  heremapapiKey: 'iQVSw9yPlsVLkIIsqZu5VpZYDRF7V0PHyofw0fUUMo0',
  googleAnalyticsKey: 'G-7QM35ZHWYK',
  paymentApiEndpoint: 'https://payapi-pre.laneaxis.com',
  axisTokanApiEndpoint: 'https://axispayapi-pre.laneaxis.com',
  magicLinkPublicKey: 'pk_live_6B11813D1EE729CB',
  imagePathUrl: 'https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com',
  inviteLink: 'https://onelink.to/laneaxis-inc/',
  serverPathForUrl: 'https://staticprod.laneaxis.com',
  firebase: {
    apiKey: 'AIzaSyB4EdijdMMSGlI7dGtsKf0UOy27nhTdDW4',
    authDomain: 'laneaxis-pre-81e2a.firebaseapp.com',
    databaseURL: 'https://laneaxis-network-pre.firebaseio.com',
    projectId: 'laneaxis-pre-81e2a',
    storageBucket: 'laneaxis-pre-81e2a.appspot.com',
    messagingSenderId: '474271573233',
    appId: '1:474271573233:web:eed1c160223101c763b550',
  },
};
